.Container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    //overflow-y: auto;
    padding: 0 0 20px;

    .title {
      //font-family: $PPMigra-extra-bold;
      font-size: 3rem;
      text-transform: uppercase;
      //line-height: vw_d(128);
      text-align: center;
      margin: 40px 0 25px;
    }

    .itemsWrapper {
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      grid-column-gap: 10px;
      grid-row-gap: 10px;
    }
  }
}

.popup {
  z-index: 99999;
  position: fixed;
  top: 0 !important;
  left: 100% !important;
  height: 100% !important;
  transition: transform 0.2s ease-in-out !important;
  transform: translateX(100%);
  width: 580px;

  &.active {
    right: 0px !important;
    left: auto !important;
    transform: translateX(0);
  }
}

.mobileWrapper {
  padding: unset !important;

  .mobileItemsWrapper {
    display: flex !important;
    flex-wrap: wrap;
    padding: 1rem;
    grid-column-gap: unset;
    grid-row-gap: unset;
  }
}

.mobilePopup {
  width: 100%;
}

.loading {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
