.wrapper {
  padding: 20px;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 1px solid #dddbdb;
    margin-top: 80px;

    .text {
      font-size: 4rem;
      color: #d9d9d9;
      text-transform: capitalize;
    }

    .credit {
      opacity: 0.57;
      padding-bottom: 6px;
    }
  }

  .eagles {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    margin-top: 80px;

    &.mobileWrapper {
      display: flex;
      flex-direction: column;
      padding: 16px;
    }
  }
}

.buyButton {
  margin-top: 60px;
  width: 100%;
  height: 78px;
  background-image: linear-gradient(
    to right,
    #667eea,
    #764ba2,
    #6b8dd6,
    #8e37d7
  );
  box-shadow: 0 4px 15px 0 rgba(116, 79, 168, 0.75);
  border-radius: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 300% 100%;
  transition: all 0.4s ease-in-out;

  &:hover {
    background-position: 100% 0;
    transition: all 0.4s ease-in-out;
  }
}

.mobilePoints {
  display: flex;
  justify-content: space-between;
  background-color: #151414;
  height: 50px;
  align-items: center;
  padding: 16px;

  .text {
    color: #f6f6f6;
    text-shadow: 0 -10px 30px rgba(0, 0, 0, 0.16);
    font-size: 20px;
  }

  .points {
    font-size: 12px;
    opacity: 0.8;
  }
}

.notConnectedWrapper {
  padding: 160px 80px;
}
