
.buttonWrapper {

  cursor: pointer;
  font-weight: 700;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px;


  &:hover {
    background: #374250;
  }

  .text {
    margin-left: 8px;
  }
}

.buttons {

  margin-top: 24px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .button {
    margin-top: 24px;
    background: #29313A;
    border-radius: 8px;
    width: 100%;

  }

}

.header {
  text-align: center;
}

.image {
  width: 40px;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
