
// logo

.logo {
  width: 12rem;
  @media screen and (max-width: 773px) {
    width: 9rem;
  }
  @media screen and (max-width: 500px) {
    width: 8rem;
  }
  @media screen and (max-width: 370px) {
    width: 5rem;
  }
}


// sidebar

.sidebar {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 1070;
    transition: all 0.5s ease;
    top: 0;
  
    &.show {
      left: 0%;
    }
    &.hide {
      left: -100%;
    }
  }

