/* ===== Scrollbar CSS ===== */
  /* Firefox */
  .scrollbar {
    scrollbar-width: auto;
    scrollbar-color: $primary $gray;
  }

  /* Chrome, Edge, and Safari */
  .scrollbar::-webkit-scrollbar {
    width: 0.1rem;
  }

  .scrollbar::-webkit-scrollbar-track {
    background: $gray;
    border-radius: 1rem;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background-color: $primary;
    border-radius: 1rem;
   
  }