.wrapper {

  flex: 1 1 25%;
  margin: 5px;
  box-sizing: border-box;
  text-align: center;
  position: relative;



  .image {
    background-size: cover;
    background-position: center;
    background-color: white;
    width: 100%;
    display: flex;
    cursor: pointer;

    transition: opacity 0.5s;
    height: 330px;

    &:hover {
      opacity: 0.8;
    }

    .price {
      margin-top: auto;
      margin-left: auto;
      height: 40px;
      width: 100px;
      background: #925AF0 0% 0% no-repeat padding-box;
      box-shadow: -40px 3px 30px #0000001A;
      border-top-left-radius: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #F6F6F6;
      font-size: 0.8rem;
    }
  }

  .itemDetails {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
    color: #F6F6F6;


    .title {
      text-align: left;
      white-space: break-spaces;
      overflow: hidden;
      height: 48px;
      width: 100%;
    }

    .remaining {
      color: #F7F7F7;
      margin-top: 8px;
      opacity: 0.7;
    }
  }
}

.mobileWrapper {
  width: 47%;
  padding-left: 10px;
  margin: 18px 0 0;
  flex: unset;

  .price {
    height: 32px !important;
    width: 120px !important;
  }

  .itemDetails {
    margin-top: 0.5rem;
    font-size: 12px;

    .remaining {
      margin-top: 0.25rem;
    }
  }

  .image {
    height: 180px;
  }
}

