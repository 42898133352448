.wrapper {
  width: 100%;
  background: #2f2f2f;
  height: 120px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #cbcbcb;

  .title {
    font-size: 20px;
  }

  .trait {
    font-size: 16px;

  }

  .percentage {
    font-size: 16px;
  }
}
