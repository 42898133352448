// Then add additional custom code here

html {
  @media screen and (min-width: 2000px) {
    font-size: 120%;
  }
  @media screen and (min-width: 2500px) {
    font-size: 150%;
  }
  @media screen and (min-width: 3000px) {
    font-size: 180%;
  }
  @media screen and (min-width: 3500px) {
    font-size: 250%;
  }
}

body {
  font-family: "Lato", sans-serif;
}

.font-migra {
  font-family: "migraextrabold";
}

.container {
  @media screen and (min-width: 2000px) {
    max-width: 1700px;
  }
  @media screen and (min-width: 2500px) {
    max-width: 2300px;
  }
  @media screen and (min-width: 3000px) {
    max-width: 2600px;
  }
  @media screen and (min-width: 3500px) {
    max-width: 3300px;
  }
}

small,
.small {
  font-size: 85%;
}

// remove focus

button:focus,
.btn:focus {
  box-shadow: unset;
}

// line-height

.lh-small {
  line-height: 0.85;
}

// buttons

.btn {
  &:active {
    transform: scale(1.03);
  }
}

.btn-g-primary {
  background: transparent linear-gradient(95deg, #4b89dd 0%, #9c68f5 100%) 0% 0%
    no-repeat padding-box;
  color: $white;

  position: relative;
  overflow: hidden;

  &.active,
  &:focus,
  &:hover {
    background: transparent linear-gradient(102deg, #9c68f5 0%, #5784e0 100%) 0%
      0% no-repeat padding-box;
    color: $white;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    opacity: 1;
    -webkit-transform: translate(-105%, 0);
    transform: translate(-105%, 0);
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: rgba(255, 255, 255, 1);
    background-color: rgba(255, 255, 255, 0.25);
  }

  &:after {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
  }

  &:hover:before,
  &:hover:after {
    opacity: 0;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

.btn-arrow {
  color: $gray;

  height: 2.8rem;

  &.active,
  &:hover,
  &:focus {
    color: $white;
  }
}

// links

.cl-effect-5 {
  a {
    cursor: pointer;
    overflow: hidden;
    position: relative;
    padding: 0 4px;
    display: inline-block;
    height: 1.3rem;
    span {
      color: $body-color;
      display: inline-block;
      -webkit-transition: -webkit-transform 0.3s;
      -moz-transition: -moz-transform 0.3s;
      transition: transform 0.3s;
      &::before {
        position: absolute;
        white-space: pre;
        top: 100%;
        content: attr(data-hover);
        font-weight: 600;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }
    }

    &.active,
    &:focus,
    &:hover {
      span {
        color: $primary;
        -webkit-transform: translateY(-100%);
        -moz-transform: translateY(-100%);
        transform: translateY(-100%);
      }
    }
  }
}

// icons size

.icon-size {
  &-1 {
    height: 2.2rem;
  }

  &-2 {
    display: inline-block;
    height: 2rem;
    width: 2rem;
    min-width: 2rem;
  }

  &-3 {
    display: inline-block;
    height: 3.5rem;
    width: 3.5rem;
    min-width: 3.5rem;
  }

  &-small {
    display: inline-block;
    height: 1.3rem;
    width: 1.3rem;
    min-width: 1.3rem;
  }

  &-small-2 {
    display: inline-block;
    height: 0.9rem;
    width: 0.9rem;
    min-width: 0.9rem;
  }
}

// titles

.big-title-1 {
  font-size: 7.6875rem;
  line-height: 0.8;
  letter-spacing: 0px;
  color: #dddbdb;
  text-shadow: 0px -10px 30px #00000029;

  @media screen and (max-width: 450px) {
    font-size: 6.3rem;
  }
}

.big-title {
  font-size: 7.6875rem;
  line-height: 0.8;
  letter-spacing: 0px;
  color: #dddbdb;
  text-shadow: 0px -10px 30px #00000029;

  @media screen and (max-width: 450px) {
    font-size: 4rem;
  }
}

// bg

.bg-g-primary {
  background: transparent linear-gradient(95deg, #4b89dd 0%, #9c68f5 100%) 0% 0%
    no-repeat padding-box;
}

.bg-fixed {
  background-size: contain;
  background-attachment: fixed;
}

.bg-fixed-right {
  background-repeat: no-repeat;
  background-position: top right;
}

.bg-fixed-bottom-right {
  background-repeat: no-repeat;
  background-position: bottom right;
}

.bg-cover-center {
  background-size: cover;
  background-position: center;
}

// Border Radius

.rounded-lg {
  border-radius: 1.3rem !important;
}

.rounded-lg-right-top {
  border-radius: 1rem 0 0 0;
}

//  swiper

.swiper {
  padding-bottom: 4rem !important;
  @media screen and (max-width: 767.99px) {
    padding-bottom: 2rem !important;
  }
}

.bannerSwiper {
  padding-bottom: 0 !important;
}

.swiper-pagination-bullet {
  background-color: $white !important;
  opacity: 0.6 !important;

  &-active {
    background-color: $primary !important;
    opacity: 1 !important;
  }
}

.swiper-center {
  .swiper-wrapper {
    justify-content: center;
  }
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-horizontal > .swiper-scrollbar {
  background-color: $secondary;
  width: 70% !important;

  @media screen and (max-width: 767.99px) {
    width: 100% !important;
  }
}

.swiper-scrollbar-drag {
  background-color: $primary !important;
}

.swiper-button-next,
.swiper-button-prev {
  color: $primary;
}

// banners

.banner-1 {
  height: 28rem;
}

.banner-2 {
  height: 24rem;
}

//  truncate

.text-truncate-line-3 {
  height: 4.5rem;
  overflow: hidden;
}

// pos

.pos-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// badge

.badge-1 {
  position: absolute;
  height: 4rem;
  width: 13rem;
  top: -4rem;
  right: -2.4rem;

  &-name {
    position: absolute;
    top: 37%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.market-tag {
  height: unset;
  top: -11rem;
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
  padding: 0;
}

p {
  margin: 0;
}

button,
input[type="submit"],
input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.MuiOutlinedInput-notchedOutline {
  border-color: white !important;
}

.MuiOutlinedInput-input {
  color: white !important;
}

.MuiAccordionDetails-root {
  padding: 0 !important;
  border-top: unset !important;
}

.MuiAccordion-root {
  background: unset !important;
}

.MuiAccordionSummary-root {
  padding: 0 !important;
}
.MuiAccordionSummary-content {
  margin: 0 !important;
}

.bg-white {
  background-color: unset !important;
}

// ===

.card-height {
  .MuiCollapse-entered {
    flex: 1 1 auto !important;
    .MuiCollapse-vertical,
    .MuiAccordion-region,
    .MuiAccordionDetails-root {
      height: 100%;
    }
  }
}

.home-page {
  section {
    //padding-top: 5rem;

  }
}

.collection-section {
  z-index: 2;
  position: relative;
}
