// Include any default variable overrides here (though functions won't be available)

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1272px,
);

$body-bg: #000;

$body-color: #eaeaea;

$white: #fff;
$gray: #adadad;
$light: #f6f6f6;
$primary: #9c68f5;
$secondary: #525252;
$danger: #ff4136;
$dark: #2b2b2b;
$success: #69e693;
$warning: #e9af36;
$info: #5784e0;
$black: #151414;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "danger": $danger,
  "success": $success,
  "warning": $warning,
  "info": $info,
  "light": $light,
  "dark": $dark,
  "black": $black,
  "gray": $gray,
);

$form-check-input-checked-bg-color: $primary;
$dropdown-link-hover-bg: $white;
$dropdown-link-active-bg: $primary;
$dropdown-link-hover-color: $primary;

$table-hover-bg-factor: 1;
$table-hover-bg: rgba($white, $table-hover-bg-factor);

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");
