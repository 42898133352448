.wrapper {

  .actionButtons {
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    margin-bottom: 2rem;

    span {
      cursor: pointer;
    }
  }

  height: 100%;
  background: #211E1E;

  color: #F6F6F6;
  font-size: 20px;
  padding: 2rem;
  overflow: auto;


  .imageWrapper {

    display: flex;
    justify-content: center;

    .image {
      min-width: 100%;
      min-height: 100%;
    }
  }

  .titleAndSubTitle {
    margin-top: 1.5rem;

    .subtitle {
      margin-top: 1.5rem;
    }
  }

  .quantity {
    margin-top: 2.5rem;

    .quantityDetails {
      margin-top: 2rem;

      display: flex;
      align-items: center;

      .quantityController {
        width: 130px;
        background: #525252;
        display: flex;
        justify-content: space-around;
        height: 50px;
        align-items: center;
        border-radius: 39px;

        .button {
          cursor: pointer;
          transition: all 0.3s ease-in-out;

          &:hover {
            transform: scale(1.5);
          }
        }
      }

      .price {
        align-self: flex-end;
        margin-left: 8px;
        color: #F6F6F6;
        font-size: 20px;
        line-height: 1.4;
      }
    }

  }

  .buyButton {
    border: 0;
    margin-top: 60px;
    width: 100%;
    height: 78px;
    background-image: linear-gradient(to right, #667eea, #764ba2, #6B8DD6, #8E37D7);
    box-shadow: 0 4px 15px 0 rgba(116, 79, 168, 0.75);
    border-radius: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: 300% 100%;
    transition: all .4s ease-in-out;

    &:hover {
      background-position: 100% 0;
      transition: all .4s ease-in-out;
    }
  }
  .disabled {
    background-color: grey;
    background-image: unset;
    box-shadow: unset;
    cursor: not-allowed;
  }

}

.test {
  height: 100%;
}

.userDetails {
  margin-top: 24px;

  .emailInput {
    margin-top: 24px;
  }
}

.mobileWrapper {
  .actionButtons {
    margin-bottom: unset;
  }

  .image {
    height: 270px !important;
  }

  .buyButton {
    width: 100%;
  }

  .titleAndSubTitle {
    margin-top: 1rem;
    font-size: 20px;

    .subtitle {
      margin-top: 6px;
      font-size: 14px;
      opacity: 0.7;
    }
  }

  .quantity {
    margin-top: 1rem;

    .quantityController {
      height: 42px !important;
      width: 102px !important;
    }

    .price {
      font-size: 1rem !important;
      opacity: 0.7;
    }
  }
}

.loading {
  background: grey !important;
  cursor: not-allowed;
}

.terms {
  cursor: pointer;
  text-decoration: underline;
}

.flipCard {
  background-color: transparent;

  //border: 1px solid #f1f1f1;
  //perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flipCardInner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}


.IsFlipped {
  transform: rotateY(180deg);
}
/* Position the front and back side */
.flipCardFront, .flipCardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flipCardFront {

}

/* Style the back side */
.flipCardBack {
  transform: rotateY(180deg);
}
