.wrapper {
  width: 100%;
  max-width: 490px;
  .image {
    width: 100%;
    display: flex;
    opacity: 1;
    height: 23.75rem;

    &.mobileImage {
      height: 23.75rem;
    }
    .eagleImage {
      width: 100%;
    }
  }

  .separator {
    background-color: #000000;
    height: 1px;
    width: 100%;
    display: block;
  }

  .details {
    background: #242323;
    padding: 1rem 1rem 1rem;
    display: flex;
    justify-content: space-around;
    flex-direction: column;

    .rankAndLinks {
      display: flex;
      justify-content: space-between;
    }

    .rankAndId {
      display: flex;
      flex-direction: column;

      .id {
        font-size: 18px;
        color: #ffffff;
        opacity: 0.8;
      }

      .rank {
        font-size: 16px;
        color: #ffffff;
        opacity: 0.5;
      }
    }

    .date {
      display: flex;
      color: #ffffff;
      opacity: 0.51;

      .downloadButton {
        background: #76737b;
        color: #fff;
        height: 38px;
        width: 130px;
        display: flex;
        justify-content: center;
        border-radius: 24px;
        align-items: center;
        background-size: 300% 100%;
        transition: all 0.4s ease-in-out;

        &:hover {
          opacity: 0.8;
        }
      }

      .openSeaButton {
        margin-left: 8px;
        .openSea {
          width: 36px;
          height: 36px;
        }
      }
    }

    .properties {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      transition: all 0.5s;

      .title {
        color: white;
        opacity: 0.7;
        font-size: 16px;
      }
      img {
        width: 14px;
        margin-top: 8px;
        transition: all 0.5s;
      }

      .rotate {
        transform: rotateX(180deg);
      }
    }
  }
}

.eagleDetails {
  color: white;
  background: #242323;
  font-size: 14px;
  opacity: 0.8;
  padding: 20px;
}

.eagleDetailsAndTraits {
  height: 100%;
  color: white;
  background: #242323;
  font-size: 14px;
  opacity: 0.8;
  padding: 20px;

  display: flex;
  flex-direction: column;

  .traits {
    flex-direction: column;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 10px;
    grid-row-gap: 24px;
  }

  .dailyPoints {
    margin-top: auto;
    padding-top: 32px;
    font-size: 18px;
    color: #cbcbcb;
  }
}

.copy {
  display: flex;
  justify-content: space-between;
  .copyButton {
    width: 14px;
    cursor: pointer;
  }
}

.image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: center;
}

.actionButtons {
  display: flex;
  .downloadButton {
    background: #76737b;
    color: #fff;
    height: 38px;
    width: 130px;
    display: flex;
    justify-content: center;
    border-radius: 24px;
    align-items: center;
    background-size: 300% 100%;
    transition: all 0.4s ease-in-out;

    &:hover {
      opacity: 0.8;
    }
  }
}

.openSeaButton {
  margin-left: 8px;
  .openSea {
    width: 36px;
    height: 36px;
  }
}
